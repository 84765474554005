/* html[dir="rtl"] .closed-sidebar .app-sidebar .app-sidebar__inner ul li a {
	text-indent: 99rem;
} */

/* html[dir="rtl"] .closed-sidebar .app-sidebar .app-sidebar__inner ul li a:hover {
	text-indent: -99rem;
} */

html[dir="rtl"]
	.closed-sidebar
	.app-sidebar
	.app-sidebar__inner
	.app-sidebar__heading::before {
	left: unset;
}

html[dir="rtl"]
	.heb-text-align {
	text-align: right;
}

html[dir="rtl"] #hint_color
 {
	left: 10px !important;
}

html[dir="rtl"] #hint_color_t
 {
	left: 10px !important;
}


html[dir="rtl"] .logo-src img {
	float: right;
}

html[dir="rtl"] .card-header a {
	right: 10px !important;
}

html[dir="rtl"] .fixed-sidebar .app-main .app-main__outer {
	padding-left: unset;
	padding-right: 190px;
}

html[dir="rtl"] .closed-sidebar.fixed-sidebar .app-main__outer {
	padding-right: 80px !important;
}

@media (max-width: 991.98px) {
	html[dir="rtl"] .closed-sidebar.fixed-sidebar .app-main__outer {
		padding-right: 0px !important;
	}
	html[dir="rtl"] .closed-sidebar .app-sidebar .app-sidebar__inner ul li a {
		text-indent: -99rem;
	}
	html[dir="rtl"] .app-sidebar__inner
{
	direction: ltr !important;
}
}

html[dir="rtl"] .app-header .app-header__content .app-header-right {
	margin-left: unset;
	margin-right: auto;
}

html[dir="rtl"] .select-search-box__options {
	text-align: right;
	padding-right: 0px;
}

html[dir="rtl"] .warning-label-password-change {
	text-align: right;
}

html[dir="rtl"] .page-title-icon {
	margin: 0px 0px 0px 30px;
}

html[dir="rtl"] .page-title-heading .text-left {
	text-align: right !important;
}

html[dir="rtl"] #create-card-form label {
	left: unset;
	right: 1.2em;
}

html[dir="rtl"] .col-form-label {
	text-align: right !important;
}

html[dir="rtl"] .image-boxed-model {
	text-align: right;
}

html[dir="rtl"] .float-right {
	float: left !important;
}

html[dir="rtl"] #create-card-form #card_url {
	padding-right: 71px;
}

html[dir="rtl"] #create-card-form .card-url-typo {
	display: block;
}


/* html[dir="rtl"] #create-card-form .available-status {
	right: unset;
	left: 10px;
} */

html[dir="rtl"] .form-error-mode {
	float: right;
}

html[dir="rtl"] #create-card-form .btn-mob {
	margin-left: unset;
	margin-right: 10px;
}

html[dir="rtl"] #avatar-placeholder {
	text-align: right;
}

html[dir="rtl"] .col-md-12 {
	text-align: right;
}

html[dir="rtl"] .mr-2 {
	margin-right: unset;
	margin-left: 0.5rem !important;
}

html[dir="rtl"] .vertical-nav-menu i.metismenu-icon {
	left: unset !important;
	right: 5px !important;
}

html[dir="rtl"] .vertical-nav-menu i.metismenu-state-icon {
	left: auto !important;
	right: unset !important;
}

html[dir="rtl"] .mr-3,
.mx-3 {
	margin-left: 1rem !important;
}

@media (min-width: 576px) {
	html[dir="rtl"] .offset-sm-2 {
		margin-right: 16.66667%;
		text-align: right;
	}
}

html[dir="rtl"] .hb 
{
	direction: ltr;
}
html[dir="rtl"] .app-sidebar__inner
{
	direction: ltr !important;
}
html[dir="rtl"] .form-check-label
{
	padding-right: 20px;
}
html[dir="rtl"] #hb_cardurl 
{
	direction: rtl !important;
}
