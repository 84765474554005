@import "https://fonts.googleapis.com/css?family=Cabin:400,700";

.f-2 {
  font-size: 2em;
}

[class*="fontawesome-"]:before {
  font-family: "fontawesome", sans-serif;
}

.select-search-box {
  position: relative;
  background: #fff;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

#hint_color {
  position: absolute;
  left: 144px;
  bottom: 47px;
}
#hint_color_t {
  position: absolute;
  left: 200px;
  bottom: 41px;
}
/* .select-search-box::after {
    font-family: fontawesome;
    content:"\f078";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    line-height: 50px;
    color: #222f3e;
    z-index: 1;
} */

/* .select-search-box--multiple::after, .select-search-box--focus::after {
    content:"\f002";
} */

.select-search-box__options {
  padding-left: 0px;
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  font-size: 15px;
  padding: 0 12px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 40px;
  outline: auto;
  outline-color: #ced4da;
  /* box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07); */
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  max-height: 165px !important;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

input.select-search-box__search::placeholder {
  font-style: normal !important;
  font-size: 1rem;
  color: #495057;
}
.app-page-title {
  padding: 54px;
  margin: -60px -30px 0px;
}

.alertify-notifier .ajs-message.ajs-success {
  color: white;
  font-size: 18px;
  opacity: 0.7;
}
.warning-label-password-change {
  color: red;
  font-size: 12px;
  font-style: italic;
}

.form-error-mode {
  display: none;
  font-style: italic;
  color: red;
}

.alertify-notifier .ajs-message.ajs-error {
  color: white;
}

.toggle-sw {
  background: none;
  border: none;
  cursor: pointer;
}
.toggle-sw-p {
  background: none;
  border: none;
}

@media (max-width: 991.98px) {
  .app-header-right {
    margin: 0% !important;
    text-align: center !important;
    width: 100% !important;
  }
  .app-header .app-header__content .header-btn-lg {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
  }
}
.subcription-head-eng {
  background-color: #20badc;
}
.subcription-body-eng {
  color: #2184ff;
}
.subcription-head-heb {
  background-color: #44cbc5;
}
.subcription-body-heb {
  color: #44cbc5;
}
.subcription-head-ara {
  background-color: #3772ac;
}
.subcription-body-ara {
  color: #3772ac;
}
.subcription-pkg {
  font-size: 60px !important;
  bottom: 0px !important;
}
.subcription-pkg > sub {
  font-size: 20px !important;
  bottom: 0px !important;
}
