#create-card-form label {
  font-size: 1em;
  font-weight: 500;
  left: 1.2em;
  position: absolute;
  top: .5em;
  transition: all 0.1s linear;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#create-card-form input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #b5bdce;
  color: #666;
  font-size: 1.5em;
  padding: 0.2em 0.4em 0.4em;
  /* padding: .75em .5em .5em; */
  width: 100%;
}

.custom-color
{
  background-color : #8080801a !important;
}


#create-card-form input:focus + label,
#create-card-form input + label.show {
  border-radius: 4px;
  color: #212123;
  font-size: 0.9em;
  left: 0em;
  padding: 0 .15em;
  text-transform: uppercase;
  top: -1.8em;
}

#create-card-form .form-group{
  margin-top: 1.5em;
}

#create-card-form .card-url-typo{
  display: inline-block;
  position: absolute;
  background: #212123;
  padding: 0.28em;
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 1.28em;
  font-weight: 600;
  font-style: italic;
  transition: all 0.1s linear;
}

#create-card-form #card_url{
  padding-left: 8.7em;
}

.hide-it{
  display: none !important;
}

#create-card-form .react-switch-handle{
  top: 0px !important;
  height: 22px !important;
  width: 22px !important;
  left: -7px;
  border: 1px solid !important;
}

#create-card-form .btn-span{
  padding: 0px 10px;
  font-size: 1.3em;
  font-weight: 500;
}

#create-card-form .switch-create-card{
  width: 50px;
  padding: 3px;
  z-index: 1;
}

#create-card-form .react-switch-bg{
  height: 17px !important;
  width: 38px !important;
  /* background: #2088cd !important; */
}

#create-card-form .react-switch-bg > div{
  height: 18px !important;
  width: 20px !important;
}

#create-card-form .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 25px;
  width: 90%;
}

#create-card-form .file-info{
  /* display: inline-block; */
  float: right;
  border: 2px solid;
  padding: 7px 14px;
  font-size: 17px;
  margin: 10px;
  margin-top: 0px;
  display: none;
}

#create-card-form .btn {
  /* border: 2px solid gray;
  color: gray;
  background-color: white;
  border-radius: 8px;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
  transition: all 0.1s linear; */
}

#create-card-form .upload-btn-wrapper> .btn {
  border: 2px solid transparent;
}

#create-card-form .upload-btn-wrapper:hover > .btn{
  border: 2px solid #2088cd;
  color: #2088cd;
}

#create-card-form .upload-btn-wrapper input[type=file]:hover {
  cursor: pointer;
}
#create-card-form .upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

#create-card-form .fileContainer .chooseFileButton{
  background: #00adb4 !important;
}

#create-card-form .btn-mob {
  font-size: 26px;
  margin-left: 10px;
  color: #00adb4;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  padding: 0px;
  background: unset;
  cursor: pointer;
}

#create-card-form .close-btn {
  font-size: 26px;
  margin-top: 10px;
  color: #222222;
  width: 100%;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  padding: 0px;
  background: unset;
  cursor: pointer;
}

#create-card-form #react-time-range{
  display: inline-block !important;
  padding: 0px !important;
  margin-left: 2% !important;
}

#create-card-form .available-status{
  position: absolute;
  top: 8px;
  right: 10px;
  transition: all 0.1s linear;
}

#create-card-form .file-holder{
  /* max-height: 140px; */
  margin-top: 10px;
}

#create-card-form .file-image{
  max-width: 100%;
  max-height: 100%;
  /* height: 100px;
  width: 100%; */
}

.hover {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 188, 212, 0);
  transition: background-color 0.5s;
  text-align: center;
}

.hover-text{
  font-size: 2em;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 0.1s linear;
}

.hover:hover{
  background-color: rgba(0, 188, 212, 0.8);
}
.hover:hover .hover-text{
  display: flex;
}

.the-loader{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 500px;
    align-items: center;
}

#create-card-form .pop-over{
  position: absolute;
  z-index: 2;
  left: 10em;
  bottom: 0;
}

.required-prop{
  color: red !important;
}

#create-card-form .btn-tot-UP{
  padding: 0.6em 6em;
}

#create-card-form .width-20{
  width: 30% !important;
}

#create-card-form .helper-text{
  font-style: italic;
  color: #222221;
  font-size: 1em;
  text-align: center;
}

#create-card-form .pop-over-cover{
  text-align: center;
  border: 1px solid white;
  background: red;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

#create-card-form .cropper{
  z-index: 999; 
  width: 100%; 
}

#create-card-form .cropper-btn{
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

@media (max-width: 991.98px){
#create-card-form .btn {
  width: 100% !important;
  box-shadow: unset !important;
}
#create-card-form .pop-over {
  position: absolute;
  z-index: 2;
  left: 0em !important;
  bottom: 70px !important;
}
#create-card-form .cropper-btn {
  z-index: 1;
  position: absolute;
  right: 0;
  padding: 20px;
  top: 10em !important;
}
#create-card-form .width-20 {
  width: 80% !important;
}
.app-header__logo .logo-src{
  height: unset !important;
}
.app-header__logo{
  padding: unset !important;
}
html[dir='rtl'] .sidebar-mobile-open .app-sidebar{
  transform: translateX(0px) !important;
}
html[dir='rtl'] .app-sidebar {
  transform: translateX(280px) !important;
}

}