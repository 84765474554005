html[dir="rtl"] #resume-section {
  text-align: initial;
}

html[dir="rtl"] .ion-2x {
  margin-left: 0.5em;
}
.reach-us-button {
  padding: 15px;
}

#custom-card-wrapper .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  #custom-card-wrapper .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  #custom-card-wrapper .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  #custom-card-wrapper .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  #custom-card-wrapper .container {
    max-width: 1140px;
  }
}

#custom-card-wrapper .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#custom-card-wrapper .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}

#custom-card-wrapper .col-1,
#custom-card-wrapper .col-2,
#custom-card-wrapper .col-3,
#custom-card-wrapper .col-4,
#custom-card-wrapper .col-5,
#custom-card-wrapper .col-6,
#custom-card-wrapper .col-7,
#custom-card-wrapper .col-8,
#custom-card-wrapper .col-9,
#custom-card-wrapper .col-10,
#custom-card-wrapper .col-11,
#custom-card-wrapper .col-12,
#custom-card-wrapper .col,
#custom-card-wrapper .col-auto,
#custom-card-wrapper .col-sm-1,
#custom-card-wrapper .col-sm-2,
#custom-card-wrapper .col-sm-3,
#custom-card-wrapper .col-sm-4,
#custom-card-wrapper .col-sm-5,
#custom-card-wrapper .col-sm-6,
#custom-card-wrapper .col-sm-7,
#custom-card-wrapper .col-sm-8,
#custom-card-wrapper .col-sm-9,
#custom-card-wrapper .col-sm-10,
#custom-card-wrapper .col-sm-11,
#custom-card-wrapper .col-sm-12,
#custom-card-wrapper .col-sm,
#custom-card-wrapper .col-sm-auto,
#custom-card-wrapper .col-md-1,
#custom-card-wrapper .col-md-2,
#custom-card-wrapper .col-md-3,
#custom-card-wrapper .col-md-4,
#custom-card-wrapper .col-md-5,
#custom-card-wrapper .col-md-6,
#custom-card-wrapper .col-md-7,
#custom-card-wrapper .col-md-8,
#custom-card-wrapper .col-md-9,
#custom-card-wrapper .col-md-10,
#custom-card-wrapper .col-md-11,
#custom-card-wrapper .col-md-12,
#custom-card-wrapper .col-md,
#custom-card-wrapper .col-md-auto,
#custom-card-wrapper .col-lg-1,
#custom-card-wrapper .col-lg-2,
#custom-card-wrapper .col-lg-3,
#custom-card-wrapper .col-lg-4,
#custom-card-wrapper .col-lg-5,
#custom-card-wrapper .col-lg-6,
#custom-card-wrapper .col-lg-7,
#custom-card-wrapper .col-lg-8,
#custom-card-wrapper .col-lg-9,
#custom-card-wrapper .col-lg-10,
#custom-card-wrapper .col-lg-11,
#custom-card-wrapper .col-lg-12,
#custom-card-wrapper .col-lg,
#custom-card-wrapper .col-lg-auto,
#custom-card-wrapper .col-xl-1,
#custom-card-wrapper .col-xl-2,
#custom-card-wrapper .col-xl-3,
#custom-card-wrapper .col-xl-4,
#custom-card-wrapper .col-xl-5,
#custom-card-wrapper .col-xl-6,
#custom-card-wrapper .col-xl-7,
#custom-card-wrapper .col-xl-8,
#custom-card-wrapper .col-xl-9,
#custom-card-wrapper .col-xl-10,
#custom-card-wrapper .col-xl-11,
#custom-card-wrapper .col-xl-12,
#custom-card-wrapper .col-xl,
#custom-card-wrapper .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

#custom-card-wrapper .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

#custom-card-wrapper .col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

#custom-card-wrapper .col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

#custom-card-wrapper .col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

#custom-card-wrapper .col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

#custom-card-wrapper .col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

#custom-card-wrapper .col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

#custom-card-wrapper .col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

#custom-card-wrapper .col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

#custom-card-wrapper .col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

#custom-card-wrapper .col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

#custom-card-wrapper .col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

#custom-card-wrapper .col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

#custom-card-wrapper .col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

#custom-card-wrapper .d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

#custom-card-wrapper .d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

#custom-card-wrapper .justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

#custom-card-wrapper .align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

#custom-card-wrapper .resume-wrap {
  width: 100%;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 0px;
}

#custom-card-wrapper .resume-wrap .date {
  font-weight: 900;
  font-size: 26px;
  color: #ffbd39;
  display: inline;
}

#custom-card-wrapper .resume-wrap .date i img {
  height: 30px;
  width: auto;
}

#custom-card-wrapper .resume-wrap h2 {
  font-size: 26px;
}

#custom-card-wrapper .resume-wrap .position {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#custom-card-wrapper .info-prop-p {
  display: inline;
  font-size: 25px;
  margin: 0px;
}

#custom-card-wrapper img {
  vertical-align: middle;
  border-style: none;
}

#custom-card-wrapper svg {
  overflow: hidden;
  vertical-align: middle;
}

#custom-card-wrapper .col-xs-5 {
  width: 43%;
}

/*
===========================================
			Orientation
===========================================
*/

/* @media screen and (orientation: landscape) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
} */

/*
===========================================
			Header Image
===========================================
*/

#custom-card-wrapper #header-bg {
  /* background-image:url('../images/business/1.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
#custom-card-wrapper .header-bg-text {
  color: white;
  text-transform: uppercase;
}
#custom-card-wrapper .text-background {
  background-color: #29292d;
  opacity: 0.9;
  padding: 12%;
}
#custom-card-wrapper .text-background p:first-child,
.text-background p:nth-of-type(2) {
  font-size: 42px;
  font-weight: bold;
}

#custom-card-wrapper .text-background p:nth-of-type(2) {
  margin-top: -10.5%;
}

#custom-card-wrapper .text-background > p span {
  color: #ffbd39;
}

#custom-card-wrapper .text-background p:nth-of-type(3) {
  font-size: 24px;
  margin-bottom: 6%;
}

#custom-card-wrapper .text-background p:nth-of-type(4) {
  font-size: 14px;
}

/*
===========================================
			Divider
===========================================
*/
#custom-card-wrapper #divider {
  background-color: #ffc412;
}

#custom-card-wrapper .youtube-icon,
#custom-card-wrapper .linked-icon,
#custom-card-wrapper .twitter-icon,
#custom-card-wrapper .instagram-icon {
  margin-top: 3px !important;
}

/*
===========================================
			Owner
===========================================
*/

#custom-card-wrapper #owner-background {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: white;
  overflow: hidden;
  padding-bottom: 73px;
}

#custom-card-wrapper #owner-content img {
  padding: 2% 0;
}

#custom-card-wrapper #owner-content p:first-child {
  font-size: 300%;
  text-align: center;
  line-height: 160%;
}
#custom-card-wrapper #owner-content p:last-child {
  text-align: center;
}

/*
===========================================
			Company Info
===========================================
*/

#custom-card-wrapper .logo-img {
  background-color: #222221;
  border: none;
  border-radius: 0px;
}

#custom-card-wrapper .logo-img img {
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  margin-top: -54px;
  border: 2px solid black;
  background: black;
  height: 113px;
  width: 113px;
  object-fit: cover;
}

#custom-card-wrapper .person-img {
  background: white;
  border: radius 0px;
  /* height: 120px;
	width: 225px; */
  object-fit: fill;
}

#custom-card-wrapper .about-us-section {
  color: white;
  font-size: 100%;
  width: 100%;
}

#custom-card-wrapper #name1,
#name2 {
  font-size: 1.1vw;
  line-height: 2px;
  margin-bottom: 24px;
  text-transform: capitalize;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: white;
}

#custom-card-wrapper #name2 {
  text-transform: capitalize;
}

#custom-card-wrapper #designations {
  padding: 0px 0px;
  font-size: 13px;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: white;
}

#custom-card-wrapper .company-info-wrapper {
  padding: 5% 0;
  padding-bottom: 0.5%;
}

#custom-card-wrapper .about-us-section-row {
  padding-top: 7px;
  background: #222221;
  padding-right: 0px;
  padding-bottom: 26px;
}

#custom-card-wrapper .about-us-section-row-img {
  margin-left: 15px !important;
  padding-left: 0px;
}

#custom-card-wrapper .name-title-div {
  height: 100%;
  padding-top: 28px;
  margin: auto;
  padding-left: 0px;
}

#custom-card-wrapper .img-properties {
  width: 100%;
}

/*
===========================================
			Reach US
===========================================
*/

#custom-card-wrapper .icons-div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#custom-card-wrapper .icons-div a {
  font: unset !important;
  font-size: 1.5em !important;
  border-radius: 50%;
  border: solid #ffd65a;
  color: #ffd65a;
  background-color: white;
  width: 2.26em;
  height: 2.26em;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#custom-card-wrapper .icons-div p {
  text-align: center;
}

#custom-card-wrapper .icons-div p {
  color: unset;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: unset !important;
  font-size: 1em;
  margin-top: 0.5rem;
  text-transform: capitalize;
  padding: 5px;
  margin-bottom: 16px;
}

#custom-card-wrapper #reach-us-icons {
  background-color: #ffd65a;
  background-color: #f7f7f7;
  /* padding: 16px 3px;		 */
  /* padding: 0px 24px;
  padding-bottom: 26px; */
  padding-top: 26px;
}

#custom-card-wrapper #reach-us-icons > container {
  padding-bottom: 10px;
}

#custom-card-wrapper #add-contact {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: white;
  background-color: #0099cc;
  -webkit-border: 2px solid gray !important;
  -moz-border: 2px solid gray !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  width: 100%;
  font-size: 1.1vw;
  padding: 8px 16px;
}

#custom-card-wrapper #add-contact:hover,
#add-contact:visited,
#add-contact:active,
#add-contact:link {
  background-color: #0099cc;
  cursor: pointer;
  /* opacity:0.8; */
}

#custom-card-wrapper .textarea-text {
  height: 200px !important;
  resize: none;
}
#custom-card-wrapper .email-area {
  border-radius: unset;
  padding-left: 20px;
  font-weight: 100;
  font-size: 14px;
  color: #888888 !important;
  font-family: "Open Sans", sans-serif;
}

#custom-card-wrapper #add-contact i {
  color: white;
  background-color: #0099cc;
}
#custom-card-wrapper #add-contact i:hover {
  color: #3aa241;
  background-color: #0099cc;
}

#custom-card-wrapper #icons-heading {
  font-size: 300%;
  color: dark-gray;
  margin-bottom: 1rem;
}

#custom-card-wrapper #row2-icons {
  margin-top: 9px;
  margin-bottom: 6px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
#carousel_buttons {
  border: 0px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 10px;
  opacity: 1;
  cursor: pointer;
  text-transform: uppercase;
}

#custom-card-wrapper .reach-us-button {
  /* padding: 7px; */
  width: 100%;
}

#custom-card-wrapper .reach-us-button center a svg {
  height: 26px;
  width: 39px;
  margin-left: -7px;
  fill: white;
}

#custom-card-wrapper .resume-wrap {
  margin-bottom: 17px;
  padding: 0px 5px;
  padding-left: 10px;
}

#custom-card-wrapper .my-icons {
  height: 33px;
  width: 31px;
  /* background-image: url("../images/icons/new-icons.png"); */
  margin: 10px 5px 0px 2px;
}

#custom-card-wrapper .mobile-icon {
  fill: #ffd65a;
  height: 45px;
  margin-top: 2.5px !important;
}

#custom-card-wrapper .phone-icon {
  fill: #ffd65a;
  height: 45px;
  width: 27px;
  margin-top: 2px !important;
}

#custom-card-wrapper .whatsapp-icon {
  height: 30px;
  width: 45px;
}

#custom-card-wrapper .envelope-icon {
  margin-top: 2.7px !important;
}

#custom-card-wrapper .waze-icon {
  margin-top: 2px !important;
}

#custom-card-wrapper .globe-icon {
  margin-top: 2px !important;
}

#custom-card-wrapper .facebook-icon {
  margin-top: 2px !important;
}

#custom-card-wrapper .fax-icon {
  margin-top: 2px !important;
}

#custom-card-wrapper .svg-icon {
  fill: #ffd65a;
  height: 34px;
  width: 30px;
}

/*
===========================================
			About Us
===========================================
*/
#custom-card-wrapper #about-us-background {
  background-color: #222221;
  color: white;
}

#custom-card-wrapper #about-us-container {
  background-color: #222221;
}

#custom-card-wrapper #about-us-container {
  padding: 0% 0%;
}

#custom-card-wrapper #about-us-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: white;
  text-align: center;
  padding: 0px;
  border-bottom: 2px solid white;
  font-size: 1.7vw;
}

#custom-card-wrapper #about-us-content {
  height: auto;
  background-color: #222221;
  width: 100%;
}

#custom-card-wrapper #about-us-content p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: white;
  font-size: 0.97rem;
  margin-bottom: 0px;
}

/*
===========================================
			Dropdown Create Card
===========================================
*/
#custom-card-wrapper #dropdown-background {
  background-color: #f7f7f7;
  padding: 7.5% 0;
}

#custom-card-wrapper #dropdown-container {
  padding: 0px;
}

#custom-card-wrapper #dropdown-content {
  height: auto;
  background-color: white;
  width: 100%;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  padding: 2.5% 0;
}

#custom-card-wrapper #dropdown-content p:first-child {
  font-size: 32px;
  color: black;
}
#custom-card-wrapper #dropdown-content p:last-child {
  font-size: 16px;
  color: #707583;
}

#custom-card-wrapper .date i {
  color: white;
  margin-right: 0.5em;
}

#custom-card-wrapper .info-prop-p {
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: none;
  font-size: 1.02em;
  top: 3px;
}

#custom-card-wrapper .dropdown-li {
  color: white !important;
  font-size: 1rem;
}

#custom-card-wrapper .dropdown-li h5 {
  color: lightblue !important;
}

#custom-card-wrapper .dropdown-li h6 a {
  color: white;
  cursor: pointer;
}

#custom-card-wrapper .dropdown-table {
  color: white;
  margin-top: 1rem;
}

#custom-card-wrapper .resume-wrap {
  border: 1px solid #0099cc;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  background-color: #0099cc;
  padding: 0px 10px;
  padding-top: 9px;
  padding-bottom: 10.5px;
  min-height: 52px;
}

#custom-card-wrapper .resume-wrap h2 {
  color: #768799;
  opacity: 0.7;
}

#custom-card-wrapper .resume-wrap .date {
  font-weight: normal;
  font-size: 125%;
  color: #768799;
}

#custom-card-wrapper .resume-wrap .date span ul li {
  font-weight: normal;
  font-size: 10%;
}

#custom-card-wrapper .resume-wrap h6 {
  font-weight: normal;
  font-size: 14px;
  padding: 5px 9px;
  color: white;
}

#custom-card-wrapper .ion-2x {
  filter: brightness(0) invert(1);
  font-size: unset !important;
}

#custom-card-wrapper .dropdown-paragraph-extension {
  position: relative;
}

#custom-card-wrapper .dropdown-li h6 {
  margin-top: 38px;
}

@media only screen and (max-width: 321px) {
  #custom-card-wrapper .apple-store {
    margin-bottom: 6px;
  }
}

/*
===========================================
			Image Slider
===========================================
*/

#custom-card-wrapper #img-slider-background {
  background-color: #43484d;
}

#custom-card-wrapper .img-slider-box {
  background-color: white;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

#custom-card-wrapper .img-slider-wrapper {
  padding: 1rem 0;
}

#custom-card-wrapper .img-slider-photobanner {
  position: relative;
  /* height: 233px; */
  display: flex;
  width: 100%;
  animation: bannermove 50s linear infinite alternate-reverse;
}

#custom-card-wrapper .img-slider-photobanner img {
  width: 300px;
  margin: 0px 10px;
  padding: 0px;
  box-shadow: 2px 2px 8px #8a8a8a;
}

#custom-card-wrapper .img-slider-photobanner:hover {
  animation: stop;
}

@keyframes bannermove {
  from {
    left: 0px;
  }
  to {
    left: -2700px;
  }
}

/*
===========================================
			Video Create Card
===========================================
*/

#custom-card-wrapper #video-background {
  background-color: #f7f7f7;
}

#custom-card-wrapper #video-background iframe {
  background-color: #5da399;
  -webkit-border: 8px;
  -moz-border: 8px;
  border: 8px;
  box-shadow: 0px 0px 20px 0px #f7f7f7;
}

#custom-card-wrapper .video-container-wrapper {
  padding: 0.5em;
  padding-top: 9px;
  padding-bottom: 0px;
  -webkit-overflow-scrolling: touch;
}

#custom-card-wrapper .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

#custom-card-wrapper .btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 9px 12px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 25px;
  color: white;
}

#custom-card-wrapper #video-background iframe {
  border: 5px solid #332424;
}

/*
===========================================
			Share Link
===========================================
*/

#custom-card-wrapper #share-link-background {
  background-color: #222221;
  color: white;
}

#custom-card-wrapper .share-link-icons-wrapper {
  font-size: 14px;
  padding-top: 3px;
  font-size: 14px;
  margin-bottom: 5px;
  color: white;
}

#custom-card-wrapper .share-link-icons {
  padding: 10px 0px;
}
#custom-card-wrapper .share-link-icons > span {
  font-size: 16px;
  line-height: 18px;
}

#custom-card-wrapper .share-link-icons button {
  border-radius: 25%;
}

#custom-card-wrapper .share-link-icons button:first-child {
  background-color: #8893b3;
}

#custom-card-wrapper .share-link-icons button:nth-of-type(2) {
  background-color: #38539b;
}

#custom-card-wrapper .share-link-icons button:nth-of-type(3) {
  background-color: #109956;
}

/* #custom-card-wrapper .share-link-icons button:nth-of-type(4) {
  background-color: #ff7d6b;
} */

#custom-card-wrapper .share-link-icons button:last-child {
  background-color: #ff7d6b;
}

#custom-card-wrapper .share-link-icons button svg {
  fill: white;
  margin-top: -7px;
}

#custom-card-wrapper .share-link-icons button svg:first-child {
  height: 45px;
  width: 27px;
  margin-top: -7px;
  margin-left: -1px;
}

#custom-card-wrapper .share-link-icons button svg:nth-of-type(2) {
  height: 45px;
  width: 27px;
  margin-top: -7px;
  margin-left: -1px;
}

#custom-card-wrapper .share-link-icons button svg:nth-of-type(3) {
  height: 30px;
  width: 45px;
  margin-left: -10px;
  margin-top: -7px;
}

#custom-card-wrapper .share-link-icons button svg:nth-of-type(4) {
  height: 30px;
  width: 45px;
  margin-left: -10px;
  margin-top: -7px;
}

#custom-card-wrapper .share-link-icons button svg:last-child {
  height: 27px;
  margin-left: -2px;
  margin-top: -5px;
}

/*
===========================================
			Footer
===========================================
*/
#custom-card-wrapper footer {
  background-color: #fff;
  height: 7.7rem !important;
}

#custom-card-wrapper .footer-wrapper {
  height: 100%;
}

#custom-card-wrapper .footer-wrapper p {
  padding-top: 10px;
  cursor: pointer;
  font-size: 16px;
}

#custom-card-wrapper .footer-wrapper p a {
  color: black;
}

#custom-card-wrapper #background-wrapper {
  padding: 20px;
  padding-bottom: 0px;
}

#custom-card-wrapper .dropdown-ul {
  list-style: none;
  padding: 0px;
  padding-bottom: 2px;
  display: none;
}

#custom-card-wrapper .dropdown-li {
  list-style: none;
}

#custom-card-wrapper .store-row {
  padding-top: 20px;
}

#custom-card-wrapper .table-row {
  padding: 12px !important;
}

#custom-card-wrapper .table-row th,
.table-row td {
  font-size: 0.9em;
  padding-top: 0.3em !important;
  padding-bottom: 0.3em !important;
  padding-left: 1em !important;
  background: #0091c2 !important;
  color: white;
}

#custom-card-wrapper .dropdown-li h5 {
  font-size: 20px;
  margin-bottom: 2rem;
  margin-top: 42px;
}

#custom-card-wrapper .dropdown-li hr {
  border-top: 1px solid #058cb9;
}

#custom-card-wrapper .btn-send-message:active {
  opacity: 0.8 !important;
  transform: scale(1);
}

#custom-card-wrapper .btn-send-message {
  color: #0099cc;
  background: #ffd65a;
  border-radius: 0;
  border: 1px solid !important;
  text-align: center !important;
  transform: scale(0.98);
}

#custom-card-wrapper #img-slider-wrapper {
  padding-top: 3%;
}

#custom-card-wrapper .date button {
  color: white;
  background: #0099cc;
  border: none;
  font-size: 1em;
  width: 100%;
  box-shadow: none;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: unset;
}

#custom-card-wrapper .date button:hover,
#custom-card-wrapper .date button.focus,
#custom-card-wrapper .date button:focus,
#custom-card-wrapper .date button:active,
#custom-card-wrapper .btn-info:hover,
#custom-card-wrapper .btn-info:focus,
#custom-card-wrapper .btn-info:active {
  background-color: transparent !important;
  border-color: transparent;
  outline: none !important;
  box-shadow: none;
}

#custom-card-wrapper .collapse-text,
.collapse-text a {
  color: white;
  /* padding: 1em 0px 1em !important; */
}

#custom-card-wrapper .the-padder-b {
  padding: 1em 0px 1em !important;
}

#custom-card-wrapper .collapse-text div hr {
  border-top: 1px solid #058cb9;
}

#custom-card-wrapper #collapse-text5 div h5 {
  color: lightblue;
  font-size: 20px;
  margin-bottom: 2rem;
  margin-top: 42px;
}

#custom-card-wrapper #myCarousel {
  padding: unset !important;
}

#custom-card-wrapper .hide-it {
  display: none !important;
}

#custom-height {
  height: 100%;
}
#img-slider-wrapper {
  direction: initial !important;
}
